import React from 'react';
import {Jumbotron} from '../../components/Jumbotron/Jumbotron';

export function FrontPage() {
    return (
        <>
            <Jumbotron title="JavaZone"
                       location="Oslo Spektrum"
                       subTitle="September 7th - 8th 2022"/>
        </>
    )
}
